import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='navbar-dark navbar-dark bg-light text-dark text-center footer text-wrap'>
    <div className='copyright fw-bold'>
        Copyright © info@saptarishimatrimony.com
        <Link target="_blank" rel="noopener" className=' card-text mx-3 text-decoration-none' to='https://www.instagram.com/saptarishimatrimony/'>
         Instagram
      </Link>
    </div>
    </div>
  )
}

export default Footer