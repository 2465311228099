import React from "react";
import { Link } from 'react-router-dom'

const UserInfoCard = ({user}) => {
  return (
    <>
    <div className="card-overlay">
    <div className="col">
      <div className="card h-100 d-flex flex-column justify-content-between">
        <img src={user.photoURL} className="card-img-top  p-2 border" alt="..." width='300' height='200' onError={(e) => {
          e.target.onerror = null; 
          e.target.src = "https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/noImage.png0.04549964515800853?alt=media&token=0366a258-b9e0-43a7-afb2-ec4698d53924"; // Display a fallback image
        }} style={{ objectFit: "cover" }}/>
        <div className="card-body d-flex flex-column justify-content-start card-content bg-white" >
          <h6  className="card-title text-uppercase fw-bold">{user.fullname}</h6>
          <div>
          <p className="card-text mb-0" >{user.cast}, <small className="text-muted">{user.subCast}</small></p>
          <p className="card-text mb-0">
            DOB: {user.DOB}
          </p>
          <p className="card-text">
            {user.gotherm}
          </p>
          </div>
          <div>
          <Link to='/userdetail' state={user} className="btn btn-sm btn-outline-dark mt-4">More Details</Link>
          </div>
        </div>
      </div>
    </div>
    </div>
</>

  );
};

export default UserInfoCard;
