import React from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserCard from './Components/UserCard';
import UserDetail from './Components/UserDetail';
import Footer from './Components/Footer';
import Login from './Components/Login';
import Register from './Components/Register';
import Home from './Components/Home';
import AbouUs from './Components/AbouUs';
function App() {
  return (
   <>
  
   <div className='main'>
    <BrowserRouter>
    <Navbar />
    <div className='content'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/user" element={<UserCard />} />
        <Route path="/userdetail" element={<UserDetail />} />
        <Route path="/login" element={<Login />} />
        <Route path='/about' element={<AbouUs />} />
        <Route path="/signup" element={<Register />} />
      </Routes>
    </div>
      <Footer/>
    </BrowserRouter>
    </div>
   </>

  );
}

export default App;
