import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SignIn } from "../Context/UserContext";

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [logIn, setLogIn] = useState({
    email: "",
    password: "",
  });
  const logInEvent = (e) => {
    const { name, value } = e.target;
    setLogIn((oldData) => {
      return { ...oldData, [name]: value };
    });
  };
  const submitEvent = async (e) => {
    e.preventDefault();
    setError(true);
    await SignIn(logIn.email, logIn.password)
      .then((user) => {
        navigate("/");
      })
      .catch((err) => {
        toast.error("Invalid Credential", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          setError(false);
        }, 2000);
      });
  };
  return (
    <div className="container mt-5 ">
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5">
          <div className="card">
            <div className="card-header">
              <h4 className="text-center">Login</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="mb-3">
                  <label className="form-label">Email address</label>
                  <input
                    type="email"
                    className="form-control shadow-none"
                    id="email"
                    name="email"
                    value={logIn.email}
                    onChange={logInEvent}
                    aria-describedby="emailHelp"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control shadow-none"
                    id="password"
                    name="password"
                    value={logIn.password}
                    onChange={logInEvent}
                    required
                  />
                </div>
                <div className="d-grid gap-2">
                  <button
                    type="submit"
                    disabled={error}
                    onClick={submitEvent}
                    className="btn btn-success"
                  >
                    Login
                  </button>
                </div>
                <div className="input-group justify-content-center pt-4">
                  <p className="text-capitalize px-1 fs">new to saptarishi ?</p>
                  <Link
                    to="/signup"
                    className="fw-bold text-capitalize text-decoration-none"
                  >
                    sign in
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Login;
