import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getData, useAuth, UserContext } from "../Context/UserContext";
import UserInfoCard from "./UserInfoCard";

const UserCard = () => {
  const navigate = useNavigate();
  const value = useAuth(UserContext);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [genderFilter, setGenderFilter] = useState("all");
  const [castFilter, setCastFilter] = useState("all");

  useEffect(() => {
    if (!value || !value.user ) {
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      const data = await getData();
      setUserData(data);
      setLoading(false);
    };
    fetchData();
  }, [value.user]);

  if (loading) {
    return (
      <div className="d-flex justify-content-between align-item-center">
        Loading...
      </div>
    );
  }
  const handleGenderChange = (event) => {
    setGenderFilter(event.target.value);
  };
    const handleCastFilterChange = (event) => {
    setCastFilter(event.target.value);
  };

  const filteredData = userData
    .filter((user) => {
      if (genderFilter === "all") {
        return true;
      } else {
        return user.gender === genderFilter;
      }
    })
    .filter((user) => {
      if (castFilter === "all") {
        return true;
      } else {
        return user.cast === castFilter;
      }
    });

  return (
    <>
     <div className="container">
  <nav className="navbar navbar-expand-lg navbar-light">
    <div className="container-fluid">
      <div className="fs-4 fw-bold mr-auto">User profile</div>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <div>
              <label htmlFor="genderFilter" className="me-2 align-self-center">
                Filter by Gender:
              </label>
              <select
                id="genderFilter"
                value={genderFilter}
                onChange={handleGenderChange}
                className="shadow-none form-control"
              >
                <option value="all">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </li>
          <li className="nav-item">
            <div className="mx-2">
              <label htmlFor="castFilter" className="me-2 align-self-center">
                Filter by Sub-Caste:
              </label>
              <select
                id="castFilter"
                value={castFilter}
                onChange={handleCastFilterChange}
                className="shadow-none form-control"
              >
                <option value="all">All</option>
                <option value="Vaidik">Vaidik</option>
                <option value="Sri Vaishnava">Sri Vaishnava</option>
                <option value="Vaikhanasa">Vaikhanasa</option>
                <option value="Madhwa">Madhwa</option>
                <option value="Pradhama Niyogi">Pradhama Niyogi</option>
                <option value="6000 Niyogi">6000 Niyogi</option>
                <option value="Dravida">Dravida</option>
                 <option value="Arama Dravida">Arama Dravida</option>
                <option value="Golkonda Vyapari">Golkonda Vyapari</option>
                <option value="Telaganya">Telaganya</option>
                <option value="Veginati">Veginati</option>
                <option value="Vaidika Velanadu">Vaidika Velanadu</option>
                <option value="Siva Archaka">Siva Archaka</option>
              </select>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div className="container">
    <div className="row row-cols-1 row-cols-md-1 g-4">
      {filteredData.map((user, index) => (
        <div className="col-md-4 col-xl-3 col-sm-6" key={index}>
          <UserInfoCard user={user} />
        </div>
      ))}
    </div>
  </div>
</div>;

    </>
  );
};

export default UserCard;
