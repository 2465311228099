import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logOut, useAuth, UserContext } from "../Context/UserContext";

const Navbar = () => {
  const [userLogIn, setUserLogIn] = useState(false);
  const value = useAuth(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (value.loginUser) {
      setUserLogIn(true);
    } else {
      setUserLogIn(false);
    }
  }, [value.loginUser]);

  const signOutEvent = async () => {
    logOut()
      .then(() => {
        navigate("/");
        setUserLogIn(false);

      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <nav className="navbar navbar-light navbar-expand-lg navbar-main">
      <div className="container">
        <Link to="/"> 
            <img
            src="https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/WhatsApp%20Image%202023-03-20%20at%204.37.20%20PM.jpeg?alt=media&token=7750fd10-18e6-48f8-bc45-64ecc235c3a6"
            className="navbar-brand logo"
            width="100"
            href="/"
          />  
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item text fw-bold">
              <Link to="/" className="nav-link text-white text-uppercase">
                Home
              </Link>
            </li>
            <li className="nav-item fw-bold">
              <Link to="/user" className="nav-link text-white text-uppercase">
                Users
              </Link>
            </li>
            {userLogIn ? (
              <>
                <li className="nav-item text-dark fw-bold cursor-pointer">
                  <a
                    href="#"
                    onClick={signOutEvent}
                    className="nav-link text-white text-uppercase "
                  >
                    Log out
                  </a>
                </li>
              </>
            ) : (
              <li className="nav-item text-dark fw-bold">
                <Link
                  to="/login"
                  className="nav-link text-white text-uppercase"
                >
                  Login
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
