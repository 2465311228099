import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signUp, userDetails } from "../Context/UserContext";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const Register = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    fullname: "",
    email: "",
    number: "",
    whatsAppNo: "",
    gender: "",
    DOB: "",
    timeOfBirth: "",
    birthPlace: "",
    birthStar: "",
    zodiacSign: "",
    complexion: "",
    height: "",
    motherTongue: "",
    timeOfBirth: "",
    birthPlace: "",
    birthStar: "",
    zodiacSign: "",
    complexion: "",
    height: "",
    motherTongue: "",
    briefDescription: "",
    address: "",
    photoURL: "",

    fatherName: "",
    motherName: "",
    sibilings: "",
    cast: "",
    subCast: "",
    gotherm: "",

    qualification: "",
    occupation: "",
    Salary: "",

    requirement: "",
    rightTimeToTalk: "",
    planningTimeOfMarriage: "",
    password: "",
    c_password: "",
  });
  const [photo, setPhoto] = useState(null);
  const handlePhotoChange = (event) => {
    setPhoto(event.target.files[0]);
  };
  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    setUser((oldData) => {
      return { ...oldData, [name]: value };
    });
  };
  useEffect(() => {
    if (photo) {
      const file = photo.name + Math.random();
      const storage = getStorage();
      const storageRef = ref(storage, file);
      const uploadTask = uploadBytesResumable(storageRef, photo);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const updatedUser = { ...user, photoURL: downloadURL };
            setUser(updatedUser);
          });
        }
      );
    }
  }, [photo]);
  function error(msg) {
    return toast.error(msg, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  const submitEvent = async (e) => {
    e.preventDefault();
    const values = Object.values(user);
    if (!user.email || !user.email.includes("@")) {
      error("Please enter a valid email");
      return;
    }
    if (user.password.length < 6) {
      error("Password length must be more then six");
      return;
    }
    if (user.password !== user.c_password) {
      error("Passwords do not match");
      return;
    }
    if (values.some((value) => value === "")) {
      error(`Please fill all the fields`);
      return;
    }
    try {
      await signUp(user.email, user.password).then((newUser) => {
        if (!user.photoURL) {
          user.photoURL =
            "https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/noImage.png?alt=media&token=d55bf8a0-6b48-4eed-8fa7-641f3c445d47";
        }
        userDetails(newUser.user.uid, user);
       
      });
      // Add user details to Firebase Realtime Database
      navigate("/login");
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <div className="container my-4 mx-auto">
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="row  justify-content-center align-items-center">
        <div className="col-md-12 ">
          <h3 className=" card-header text-center">Register Now</h3>
          <form className="d-flex flex-column bg-light p-3" method="POST">
            <div className="row mb-3">
              <div className="container">
                <div className="row py-2 text-center">
                  <label className="form-label fw-bold">
                    1. Personal Details
                  </label>
                </div>
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-3">
                  <div className="col">
                    <label className="form-label">Full Name</label>
                    <input
                      type="text"
                      className="shadow-none form-control name"
                      name="fullname"
                      value={user.fullname}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Email Address</label>
                    <input
                      type="email"
                      className="shadow-none form-control"
                      name="email"
                      value={user.email}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="number"
                      className="shadow-none form-control"
                      name="number"
                      value={user.number}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Whatsapp Number</label>
                    <input
                      type="number"
                      className="shadow-none form-control"
                      name="whatsAppNo"
                      value={user.whatsAppNo}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Gender</label>
                    <select
                      type="text"
                      className="shadow-none form-control"
                      name="gender"
                      value={user.gender}
                      onChange={onChangeEvent}
                      required
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="col">
                    <label className="form-label">Date of birth</label>
                    <input
                      type="date"
                      className="shadow-none form-control"
                      name="DOB"
                      value={user.DOB}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Time Of Birth</label>
                    <input
                      type="time"
                      className="shadow-none form-control"
                      name="timeOfBirth"
                      value={user.timeOfBirth}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Birth Place</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="birthPlace"
                      value={user.birthPlace}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Birth Star</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="birthStar"
                      value={user.birthStar}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Zodiac Sign</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="zodiacSign"
                      value={user.zodiacSign}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Complexion</label>
                    <select
                      type="text"
                      className="shadow-none form-control"
                      name="complexion"
                      value={user.complexion}
                      onChange={onChangeEvent}
                    >
                      <option value="">Select</option>
                      <option value="medium">Medium</option>
                      <option value="fair">Fair</option>
                      <option value="dark">Dark</option>
                    </select>
                  </div>
                  <div className="col">
                    <label className="form-label">Height</label>
                    <input
                      type="number"
                      className="shadow-none form-control"
                      name="height"
                      value={user.height}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Mother Tongue</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="motherTongue"
                      value={user.motherTongue}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Your Photo</label>
                    <input
                      type="file"
                      accept="image/*"
                      className="shadow-none form-control"
                      onChange={handlePhotoChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Address</label>
                    <textarea
                      type="text"
                      className="shadow-none form-control"
                      name="address"
                      value={user.address}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">About Me</label>
                    <textarea
                      type="text"
                      className="shadow-none form-control"
                      name="briefDescription"
                      value={user.briefDescription}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <label className="form-label fw-bold pb-2 pt-4 text-center">
                    2. Family Details
                  </label>
                </div>
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-3">
                  <div className="col">
                    <label className="form-label">Father Name</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="fatherName"
                      value={user.fatherName}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Mother Name</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="motherName"
                      value={user.motherName}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Caste</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="cast"
                      value={user.cast}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Sub-Caste</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="subCast"
                      value={user.subCast}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Gothram</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="gotherm"
                      value={user.gotherm}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Siblings</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="sibilings"
                      value={user.sibilings}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <label className="form-label fw-bold pb-2 pt-4 text-center">
                    3. Professional Information
                  </label>
                </div>
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-3">
                  <div className="col">
                    <label className="form-label">Highest Education</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="qualification"
                      value={user.qualification}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Current Job</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="occupation"
                      value={user.occupation}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Salary</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="Salary"
                      value={user.Salary}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <label className="form-label fw-bold pb-2 pt-4 text-center">
                    4. Saptarishi Information
                  </label>
                </div>
                <div className="row row-cols-1 row-cols-md-1 row-cols-lg-3 g-3">
                  <div className="col">
                    <label className="form-label">Looking for</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="requirement"
                      value={user.requirement}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Right time to contact</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="rightTimeToTalk"
                      value={user.rightTimeToTalk}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Planning to get married</label>
                    <input
                      type="text"
                      className="shadow-none form-control"
                      name="planningTimeOfMarriage"
                      value={user.planningTimeOfMarriage}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      className="shadow-none form-control"
                      name="password"
                      value={user.password}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                  <div className="col">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      className="shadow-none form-control"
                      name="c_password"
                      value={user.c_password}
                      onChange={onChangeEvent}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success"
              onClick={submitEvent}
            >
              Register
            </button>
          </form>
          <div className="input-group justify-content-center pt-2">
            <p className="text-capitalize px-1 fs">
              Already have an account ?{" "}
            </p>
            <Link
              to="/login"
              className="fw-bold text-capitalize text-decoration-none"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
