import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { addLikeEvent, useAuth, UserContext } from '../Context/UserContext';
import { db } from '../firebase';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const UserDetail = () => {
  let { state } = useLocation();
   
  const { loginUser } = useAuth(UserContext);
   useEffect(() => {
    db.ref('users').orderByChild('email').equalTo(state.email).once('value')
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const likedBy = childSnapshot.child('likedBy').val() || '';
          if (likedBy.includes(loginUser)) {
            setLiked(true);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loginUser]);
  console.log(state.likedBy)
    const [liked, setLiked] = useState(false);
    const likeEvent = () => {
      addLikeEvent(loginUser,state.email);
      setLiked(liked ? false : true);
    }
   
    const downloadInfoEvent = () => {
    const doc = new jsPDF();
    const columns = ["Field", "Value"];
    const data = [
      ["Name", state.fullname],
      ["Email", state.email],
      ["Age", (Math.floor(Date.now() - new Date(state.DOB)) / 31557600000).toFixed(0)],
      ["Height", state.height],
      ["Mother Tongue", state.motherTongue],
      ["Occupation", state.occupation],
      ["Cast", state.cast],
      ["Sub-Cast", state.subCast],
      ["Time Of Birth", state.timeOfBirth],
      ["Birth Place", state.birthPlace],
      ["Zodiac Sign", state.zodiacSign],
      ["Birth Star", state.birthStar],
      ["Gothram", state.gotherm],
      ["Mother Name", state.motherName],
      ["Father Name", state.fatherName]
    ];
    doc.autoTable({
      startY: 10,
      head: [columns],
      body: data,
      theme: "grid",
      styles: {
        head: {
          fontSize: 14,
          fontStyle: "bold"
        },
        table: {
          fontSize: 12,
          cellPadding: 5,
          lineHeight: 1.5
        }
      }
    });
    doc.save(state.fullname+Math.random().toFixed(2)+'.pdf');
    }

  return (
    <div className='container w-100 h-100 d-flex'>
        <div className="row w-100">
         <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src={state.photoURL} className="img-fluid rounded-start card-image bg-light" alt="..." onError={(e) => {
              e.target.onerror = null; 
              e.target.src = "https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/noImage.png0.04549964515800853?alt=media&token=0366a258-b9e0-43a7-afb2-ec4698d53924"; // Display a fallback image
            }} />
          </div>
          <div className="col-md-6 py-5">
              <h5 className="card-title text-uppercase fw-bold"><Link to='/user' className='btn btn-outline-dark me-3 btn-sm'><FontAwesomeIcon icon={faArrowLeft} /></Link>{state.fullname}</h5> 
              <hr></hr>
              <h6 className='card-text'><u>Personal Details</u></h6>
              <div className="row g-3">
                <div className="col-6">
                  <p className="card-text mb-0"><small className="text-muted">DOB:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Height:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Mother Tongue:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Occupation:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Caste:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Sub-Caste:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Birth Place:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Time Of Birth:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Birth Star:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Zodiac Sign:</small></p>
                </div>
                <div className="col-6">
                  <p className="card-text mb-0"><small>{state.DOB}</small></p>
                  <p className="card-text mb-0"><small>{state.height}</small></p>
                  <p className="card-text mb-0"><small>{state.motherTongue}</small></p>
                  <p className='card-text mb-0'><small>{state.occupation}</small></p>
                  <p className="card-text mb-0"><small>{state.cast}</small></p>
                  <p className="card-text mb-0"><small>{state.subCast}</small></p>
                   <p className="card-text mb-0"><small>{state.birthPlace}</small></p>
                  <p className="card-text mb-0"><small>{state.timeOfBirth}</small></p>
                   <p className="card-text mb-0"><small>{state.birthStar}</small></p>
                  <p className="card-text mb-0"><small>{state.zodiacSign}</small></p>
                </div>
              </div>
              <h6 className='card-text mt-3'><u>Family Details</u></h6>
              <div className="row g-3">
                <div className="col-6">
                  <p className="card-text mb-0 "><small className="text-muted">Father Name:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Mother Name:</small></p>
                  <p className="card-text mb-0"><small className="text-muted">Gothram:</small></p>
                 
                </div>
                <div className="col-6">
                  <p className="card-text mb-0"><small>{state.fatherName}</small></p>
                  <p className="card-text mb-0"><small>{state.motherName}</small></p>
                  <p className="card-text mb-0"><small>{state.gotherm}</small></p>
                </div>
              </div>
              <h6 className='card-text text-capitalize mt-3'>About Me: <span>{state.briefDescription}</span></h6>
              <p className="card-text"><small className="text-muted fw-bold">Looking For: <span>{state.requirement}</span></small></p>
             <div className='my-3' style={{ display: 'flex', alignItems: 'center' }}>
              {liked && loginUser ? 
                <button style={{ backgroundColor: '#ed4956', color: '#fff', border: 'none', padding: '8px 16px', borderRadius: '4px', marginRight: '16px', fontWeight: 'bold' }}>
                  <FontAwesomeIcon icon={faThumbsUp} /> Liked
                </button>
                :
                <button style={{ backgroundColor: 'transparent', color: '#ed4956', border: '1px solid #ed4956', padding: '8px 16px', borderRadius: '4px', marginRight: '16px', fontWeight: 'bold' }} onClick={likeEvent}>
                  <FontAwesomeIcon icon={faThumbsUp} /> Like
                </button> 
              }
              <button style={{ backgroundColor: '#3897f0', color: '#fff', border: 'none', padding: '8px 16px', borderRadius: '4px', fontWeight: 'bold' }} onClick={downloadInfoEvent}>
                <FontAwesomeIcon icon={faDownload} /> Download
              </button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default UserDetail