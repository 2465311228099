import React, { useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
  const currentUser = useContext(AuthContext);
  return currentUser;
}
export function SignIn(email, password) {
  return auth.signInWithEmailAndPassword(email, password);
}

export function signUp(email, password) {
  return auth.createUserWithEmailAndPassword(email, password);
}

export function userDetails(id, userData) {
  return db.ref(`users`).child(id).set(userData);
}
export async function getData() {
  try {
    const usersRef = db.ref("users");
    const snapshot = await usersRef.once("value");
    const data = snapshot.val();
    const users = Object.keys(data).map((key) => {
      return { id: key, ...data[key] };
    });
    return users;
  } catch (error) {
    throw error;
  }
}

export async function logOut() {
  return await signOut(auth);
}
export function addLikeEvent(currentUserEmail, likeRecieverEmail) {
  db.ref("users")
    .orderByChild("email")
    .equalTo(likeRecieverEmail)
    .once("value")
    .then((snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const likedBy = childSnapshot.child("likedBy").val() || "";
        if (!likedBy.includes(currentUserEmail)) {
          const updatedLikedBy = `${likedBy},${currentUserEmail}`;
          childSnapshot.ref.update({ likedBy: updatedLikedBy });
        }
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

export function currentUserData(email) {
  db.ref("users")
    .orderByChild("email")
    .equalTo("Phanideep.Viswanadha@tbccollege.com")
    .once("value")
    .then((snapshot) => {
      const userdata = snapshot.val();
      const users = Object.keys(userdata).map((key) => {
        return { id: key, ...userdata[key] };
      });
      return users;
    })
    .catch((err) => {
      console.log(err);
    });
}
export const UserContext = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loginUser, setLoginUser] = useState();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        setLoginUser(user.email);
      }
    });
    return unsubscribe;
  }, []);
  const currentUser = {
    user,
    loginUser,
  };
  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
};
