import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import dotenv from 'dotenv';
dotenv.config();


const app = firebase.initializeApp({
  apiKey: "AIzaSyDeYI4e26LYW7ET8f9JmeydxzOi8sfQ5lU",
  authDomain: "saptarishi-91c89.firebaseapp.com",
  databaseURL: "https://saptarishi-91c89-default-rtdb.firebaseio.com",
  projectId: "saptarishi-91c89",
  storageBucket: "saptarishi-91c89.appspot.com",
  messagingSenderId: "841653085367",
  appId: "1:841653085367:web:50684c67e5cf867f4cd932",
  measurementId: "G-LXR0X94LPR"
});
export const auth = app.auth();
export const db = firebase.database();
export const storage = firebase.storage();
export const storageRef = storage.ref();
export default app;
