import React from "react";

const AboutUs = () => {
  const data = [
    {
      id: 1,
      img: "https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/MicrosoftTeams-image%20(3).png?alt=media&token=2b51a5d5-a247-4633-9178-a97255029b12",
      text: "Saptarishi Matrimony,Where hearts come together, Find your soulmate for life, And cherish it forever.",
    },
    {
      id: 2,
      img: "https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/MicrosoftTeams-image%20(4).png?alt=media&token=c04b4eaa-e369-4b1b-a62a-1b803675469b",
      text: "We know love is a journey, and we're here to guide the way. Join our family at Saptarishi Matrimony and start your happily ever after today.",
    },
    {
      id: 3,
      img: "https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/MicrosoftTeams-image%20(5).png?alt=media&token=72dc3d72-cbd0-4c80-8a53-99a1d0395fbe",
      text: "A vast selection of profiles With a personalized touch, our team of experts will help you find your perfect match.",
    },
    {
      id: 4,
      img: "https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/MicrosoftTeams-image%20(7).png?alt=media&token=15f87481-294d-40e3-9f9a-52d5827d0395",
      text: "At Saptarishi Matrimony, We believe in love that lasts, So come and join our community, And find your perfect match",
    },
  ];
  return (
    <>
      <div className="row w-100 h-100 landing-page my-5">
        <div className="col-md-12">
          <h1 className="text-center card-text text-uppercase display-6 fw-bold mt-4">
            ABOUT US
          </h1>
          <hr />
        </div>

        <div className="container col-12 col-md-8 aboutUs">
          <div className="mb-4">
            <div className="mobile d-flex justify-content-start align-items-center">
              <img
                src={data[0].img}
                className="img-fluid mx-3"
                width="200"
                height="200"
              />
              <p className="text aboutUs display-7 bg-light p-2 border">
                {data[0].text}
              </p>
            </div>
          </div>
          <div className="mb-4">
            <div className="mobile d-flex justify-content-end align-items-center">
              <p className="text aboutUs display-7 about-img bg-light p-2 border">
                {data[1].text}
              </p>
              <img
                src={data[1].img}
                className="img-fluid about-p mx-3"
                width="200"
                height="200"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="mobile d-flex justify-content-start align-items-center">
              <img
                src={data[2].img}
                className="img-fluid mx-3"
                width="200"
                height="200"
              />
              <p className="text aboutUs display-7 bg-light p-2 border">
                {data[2].text}
              </p>
            </div>
          </div>
          <div className="mb-4">
            <div className="mobile d-flex justify-content-end align-items-center">
              <p className="text aboutUs display-7 about-img bg-light p-2">
                {data[3].text}
              </p>
              <img
                src={data[3].img}
                className="img-fluid about-p mx-3"
                width="200"
                height="200"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
