import React from "react";
import { Link } from "react-router-dom";
import AbouUs from "./AbouUs";

const Home = () => {
  return (
    <>
      <div className="container w-100 h-100">
        <div >
          <figure className="d-flex flex-column justify-content-center align-items-center">
            <img
              className="img-fluid border my-3 p-4"
              src="https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/MicrosoftTeams-image%20(2).png?alt=media&token=03d619a3-bc13-43d1-b2ca-179875cbc793"
            />
            <img
              className="img-fluid border my-3 p-4"
              width="500"
              src="https://firebasestorage.googleapis.com/v0/b/saptarishi-91c89.appspot.com/o/WhatsApp%20Image%202023-04-03%20at%205.15.04%20PM.jpeg?alt=media&token=fd69af07-35d6-408b-8a79-bb394eaf834d"
            />

          </figure>
        </div>
        <div className="row landing-page my-5">
          <div className=" col-md-6 py-3 order-1 d-flex flex-column align-items-start justify-content-center">
            <h1 className="card-text  text-uppercase display-6 fw-bold">
              Welcome to <span className="text-dark">saptarishi</span>
            </h1>
            <h5 className="card-text font-monospace display-9 aboutUs">
              "Find your perfect match and start your happily ever after with
              our exclusive matchmaking services"
            </h5>
            <Link to="/user" className="btn btn-success my-4">
              FIND YOUR PERFECT MATCH
            </Link>
          </div>
          <div className="col-md-6 order-0 d-flex justify-content-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/collegeproject-995f9.appspot.com/o/WhatsApp%20Image%202023-03-20%20at%205.25.42%20PM.jpeg?alt=media&token=ebb7fa94-2fda-4c27-8f8a-45bcaa80539f"
              className="img-fluid"
              width="400"
              alt="..."
            />
          </div>
        </div>
        <AbouUs />
      </div>
    </>
  );
};

export default Home;
